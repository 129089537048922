@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.scroll-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
}

.scroll-content {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spectrum-global-dimension-size-250, 20px);
  overflow-x: auto;
  padding-top: var(--mod-scroll-content-padding-top, 2px);
  padding-left: var(--mod-scroll-content-padding-left, 0);
  padding-right: var(--mod-scroll-content-padding-right, 0);

  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-item-vertical-centered {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.scroll-item {
  position: relative;
  flex: 0 0 auto;
  border-radius: 10px;
}

.scroll-button {
  position: absolute;
  width: 60px;
  height: 60px;
  color: #000;
  top: 40%;

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }
}

@media (max-width: $breakpoint-small) {
  .scroll-container {
    padding: unset;
  }
  .scroll-button {
    display: none;
  }

  .mask-left,
  .mask-right,
  .mask-both {
    mask-image: none;
  }
}

.light-button {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.11);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.dark-button {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
