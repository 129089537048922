@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(248, 248, 248, 1);
  margin-top: 140px;
  margin-bottom: 180px;
}

.video-container {
  max-width: 1084px;
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 8px 0px #00000029;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 var(--spectrum-global-dimension-size-250, 20px);
  position: relative;
  background-color: white;
}

.video {
  width: 100%;
  height: 100%;
}
.video-picker {
  gap: var(--spectrum-global-dimension-size-300, 24px);
  margin-top: var(--spectrum-global-dimension-size-700, 56px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.video-switch-button {
  display: flex;
  border: 2px solid #dadada;
  border-radius: var(--spectrum-global-dimension-size-250, 20px);
  padding-left: var(--spectrum-global-dimension-size-250, 20px);
  padding-right: var(--spectrum-global-dimension-size-250, 20px);
  background-color: transparent;

  &.active {
    background-color: rgba(233, 233, 233, 1);
    border-color: transparent;
  }

  &:hover {
    background-color: rgba(233, 233, 233, 1);
  }

  > span {
    font-size: var(--spectrum-font-size-200, 16px);
    font-weight: 700;
    line-height: 20.03px;
    text-align: left;
  }
}

.video-blurb {
  margin-top: var(--spectrum-global-dimension-size-400, 32px);
  font-size: var(--spectrum-font-size-200, 16px);
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media (max-width: $breakpoint-medium) {
  .container {
    margin-bottom: 120px;
  }

  .video-picker {
    gap: var(--spectrum-global-dimension-size-150, 12px);
  }

  .video-blurb {
    padding: 0 var(--spectrum-global-dimension-size-250, 20px);
  }
}
