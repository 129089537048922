@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(248, 248, 248, 1);
  overflow-x: hidden;
}

.header {
  position: relative;
  height: var(--onboarding-nav-height);
}

.main {
  padding-top: var(--onboarding-nav-height);
  flex: 1;
}

.feature-showcase-container {
  padding: 0 var(--spectrum-global-dimension-size-250, 20px);
}

.project-showcase-cards {
  margin-top: -260px;
}

.community-teaser {
  margin-top: var(--spectrum-global-dimension-size-300, 24px);
  margin-bottom: var(--spectrum-global-dimension-size-300, 24px);
  overflow-y: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  > img {
    align-items: center;
    display: flex;
    overflow: hidden;
    height: 170px;
    width: auto;
    object-fit: cover;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }
}

.community-project-button {
  position: absolute;
  top: 50%;
  z-index: 999;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 180px 0 24px 0;
}

.content-text {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-150, 12px);
  color: rgba(34, 34, 34, 1);
  margin-bottom: 24px;

  span,
  p {
    margin-block: unset;
    margin-inline: unset;
    text-align: center;
  }

  span {
    font-size: var(--spectrum-global-dimension-font-size-900, 36px);
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  p {
    font-size: var(--spectrum-global-dimension-font-size-400, 20px);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (max-width: $breakpoint-medium) {
  .content-container {
    padding: 120px 20px 0 20px;
  }

  .feature-showcase-container {
    padding: unset;
  }

  .content-text {
    span {
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
