.badge-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.badge {
  border-radius: 9px;
  padding: 4px 9px;
  width: fit-content;
  background: rgba(233, 233, 233, 0.6);

  > span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.02px;
  }
}
