@import 'styles/variables';

$logo-size: 72px;
$logo-size-small: 60px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 640px;
  background-color: rgba(248, 248, 248, 1);
  margin-top: 80px;
  position: relative;
  padding: 0 80px;
}

.actions {
  display: flex;
  gap: 18px;
}

.content-container {
  display: flex;
  justify-content: center;
  width: 1200px;
  overflow: hidden;
}

.content-alignment-container {
  display: flex;
  z-index: 2;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-300, 24px);

  img {
    height: $logo-size;
    width: $logo-size;
  }
}

.content-text {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-150, 12px);
  color: rgba(34, 34, 34, 1);

  span,
  p {
    margin-block: unset;
    margin-inline: unset;
  }

  span {
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  p {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.community-link {
  display: inline-block;
  font-size: 22px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  line-height: 27.54px;
}

.video-container {
  position: relative;
  height: 640px;
  min-width: 640px;
  border-radius: 50%;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: $breakpoint-extra-large) {
  .container {
    align-items: flex-start;
    margin-top: unset;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .content-alignment-container {
    padding: 40px 20px 0 20px;
  }

  .content-text {
    text-align: center;
    h1 {
      font-size: var(--spectrum-global-dimension-font-size-900, 36px);
      line-height: 40px;
    }

    p {
      font-size: var(--spectrum-global-dimension-font-size-400, 20px);
      line-height: 25.04px;
    }
  }
}

@media (max-width: $breakpoint-large) {
  .container {
    padding: unset;
  }
}

@media (max-width: $breakpoint-medium) {
  .video-container {
    border-radius: unset;
    width: 100%;
    height: 375px;
  }

  .video-container video {
    display: block;
    height: 100%;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .content {
    align-items: center;
  }

  .content-text {
    justify-content: center;
    span {
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%;
      text-align: center;
    }

    p {
      font-size: 18px;
      font-weight: var(--spectrum-global-font-weight-regular, 400);
      line-height: 27px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}

@media (max-width: $breakpoint-small) {
  .container {
    align-items: flex-start;
  }

  .content {
    width: 100%;
    max-width: 100%;

    > overlay-trigger {
      display: flex;
      justify-content: center;
    }
  }

  .img-container {
    min-height: 420px;
  }
}

@media (max-width: $breakpoint-extra-small) {
  .img-container {
    min-height: auto;
  }
}

@keyframes fade {
  0%,
  25%,
  100% {
    opacity: 0;
  }
  10%,
  15% {
    opacity: 1;
  }
}

@media (max-width: $breakpoint-large) {
  .content-text {
    p {
      margin: 8px 0px;
    }
  }
}
