@import 'styles/variables';

.container {
  display: flex;
  position: relative;
}

.project-card {
  display: flex;
  flex-direction: column;
  border-radius: var(--spectrum-global-dimension-size-150, 12px);
  border: 3px solid rgba(255, 255, 255, 1);
  background: var(--spectrum-global-color-gray-50);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
  overflow: hidden;

  &__img-container {
    position: relative;
  }

  &__img {
    object-fit: cover;
    border-radius: inherit;
  }

  @media (max-width: $breakpoint-small) {
    border-width: 2px;
  }
}

.img-placeholder {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  pointer-events: none;
}

.img-placeholder-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  overflow: hidden;
  border-radius: inherit;
}

.img-placeholder-skeleton-container {
  display: block;
  height: 100%;
  width: 100%;
}
