@import 'styles/variables';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 360px;
  box-shadow: 0px 1px 6px 0px #dadada;
  margin: 0 var(--spectrum-global-dimension-size-65, 5px)
    var(--spectrum-global-dimension-size-65, 5px)
    var(--spectrum-global-dimension-size-65, 5px);
}

.card-content {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: var(--spectrum-global-dimension-size-300, 24px);
  width: 100%;
}

.card-img {
  object-fit: cover;
  width: 360px;
  height: 261px;
  border-radius: 12px 12px 0px 0px;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: rgba(41, 41, 41, 1);
  margin-bottom: 4px;
}

.card-description {
  font-size: var(--spectrum-font-size-200, 16px);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(113, 113, 113, 1);
}

.cards > .card:nth-of-type(2) {
  flex-direction: row-reverse;

  .card-content {
    text-align: right;
    padding: 70px 60px 70px 10px;
  }
}

@media (max-width: $breakpoint-medium) {
  .card {
    justify-content: unset;
    width: 240px;
    padding-bottom: var(--spectrum-global-dimension-size-300, 24px);
  }

  .card-img {
    width: 240px;
    height: 220px;
  }

  .card-content {
    padding: var(--spectrum-global-dimension-size-300, 24px)
      var(--spectrum-global-dimension-size-300, 24px) 0
      var(--spectrum-global-dimension-size-300, 24px);
  }

  .card-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  .card-description {
    font-size: var(--spectrum-font-size-200, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
