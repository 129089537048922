@import 'styles/variables';

.actions {
  display: flex;
  gap: 18px;
}

.video {
  max-width: 1000px;
  width: 100%;
  border-radius: 8px;
}

.overlay {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  position: fixed;
  top: 104px;
  right: 24px;
  color: #ffffff;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: $breakpoint-extra-large) {
  .actions {
    justify-content: center;
    width: 100%;
    margin-top: -12px;
    margin-bottom: 16px;
  }
}
